import React from 'react';
import './Platforms.css';

const Platforms = () => {
  return (
    <div id="platforms" className="platforms">
      <h1>Platforms</h1>
      <h2>Our Platforms section showcases a diverse range of powerful tools</h2>
      <div className="platforms-grid">
        <div className="platform-item">
          <img style={{ width: '180px', height: '90px' }} src="/Assets/Platforms/aws-logo-platform.svg" alt="AWS" />
          {/* <h2>AWS</h2> */}
          <p>Harness the power of a secure, reliable global cloud infrastructure with AWS, offering seamless scalability for your applications.</p>
        </div>
        <div className="platform-item">
          <img style={{ width: '180px', height: '90px' }} src="/Assets/Platforms/salesforce-logo.svg" alt="Salesforce" />
          {/* <h2>Salesforce</h2> */}
          <p>Enhance efficiency with cloud-based solutions that automate and optimize sales, marketing, and customer engagement.</p>
        </div>
        <div className="platform-item">
          <img style={{ width: '180px', height: '90px' }} src="/Assets/Platforms/appian-logo.svg" alt="Appian" />
          {/* <h2>Appian</h2> */}
          <p>Implement low-code AI automation to streamline processes, boost efficiency, and drive innovation effortlessly.</p>
        </div>
        <div className="platform-item">
          <img style={{ width: '240px', height: '110px' }} src="/Assets/Platforms/databricks-logo.svg" alt="Databricks" />
          {/* <h2>Databricks</h2> */}
          <p>Consolidate data sources on a unified platform for AI-driven processing, analysis, and management.</p>
        </div>
        <div className="platform-item">
          <img style={{ width: '200px', height: '100px' }} src="/Assets/Platforms/microsoft-azure-logo.svg" alt="Azure" />
          {/* <h2>Microsoft Azure</h2> */}
          <p>Leverage a comprehensive cloud suite for seamless application development, deployment, and management.</p>
        </div>
        <div className="platform-item">
          <img style={{ width: '240px', height: '100px' }} src="/Assets/Platforms/google-cloud-logo.svg" alt="Google Cloud" />
          {/* <h2>Google Cloud Platform</h2> */}
          <p>Effortlessly scale your computing resources with a secure and flexible digital infrastructure.</p>
        </div>
        <div className="platform-item">
          <img style={{ width: '240px', height: '120px' }} src="/Assets/Platforms/symbox-platform-logo.svg" alt="Symbox" />
          {/* <h2>Symbox</h2> */}
          <p>Achieve business agility and accelerate time to value with optimized processes and faster development cycles.</p>
        </div>
        <div className="platform-item">
          <img style={{ width: '280px', height: '120px' }} src="/Assets/Platforms/servicenow-logo.svg" alt="ServiceNow" />
          {/* <h2>ServiceNow</h2> */}
          <p>Centralize IT service management with automated workflows and seamless integration for enhanced efficiency and control.</p>
        </div>
        <div className="platform-item">
          <img style={{ width: '250px', height: '100px' }} src="/Assets/Platforms/ms-dynamics-logo2.svg" alt="Microsoft Dynamics" />
          {/* <h2>Microsoft Dynamics 365</h2> */}
          <p>Integrate AI-powered CRM and ERP systems to enhance team collaboration and boost customer engagement.</p>
        </div>
        <div className="platform-item">
          <img style={{ width: '240px', height: '100px' }} src="/Assets/Platforms/sharepoint-1.svg" alt="Sharepoint" />
          {/* <h2>SHAREPOINT</h2> */}
          <p>Enhance organizational agility and streamline collaboration with comprehensive integration solutions.</p>
        </div>
        <div className="platform-item">
          <img style={{ width: '180px', height: '100px' }} src="/Assets/Platforms/micro-focus-logo.png" alt="Oen Text | Micro Focus" />
          {/* <h2>Oen Text | Micro Focus</h2> */}
          <p>Organize, integrate, and safeguard data across internal and external business processes for seamless operations.</p>
        </div>
        <div className="platform-item">
          <img style={{ width: '240px', height: '100px' }} src="/Assets/Platforms/sitecore-logo.svg" alt="Sitecore" />
          {/* <h2>SITECORE</h2> */}
          <p>Provide personalized and seamless experiences at every customer touchpoint to drive engagement and satisfaction.</p>
        </div>
      </div>


      <div id="aiplatforms" className="aiplatforms">
        <h1>AI Platforms</h1>
        <h2>Our AI Platforms drive intelligent automation solutions across various industries</h2>
      </div>
      <div className="aiplatforms-grid">
        <div className="aiplatforms-item">
          <img style={{ width: '220px', height: '110px' }} src="/Assets/Platforms/meta-logo.svg" alt="Meta" />
          {/* <h2>META</h2> */}
          <p>Utilize AI technology skilled in complex reasoning, idea visualization, and nuanced problem-solving to drive innovation and efficiency.</p>
        </div>
        <div className="aiplatforms-item">
          <img style={{ width: '210px', height: '105px' }} src="/Assets/Platforms/open-ai-logo.svg" alt="Open AI" />
          {/* <h2>OPEN AI</h2> */}
          <p>
            Integrate advanced fluency in human-like language comprehension and generation for enhanced communication and interaction.</p>
        </div>
        <div className="aiplatforms-item">
          <img style={{ width: '240px', height: '100px' }} src="/Assets/Platforms/google-ai-logo-2.svg" alt="Google AI" />
          {/* <h2>GOOGLE AI</h2> */}
          <p>Incorporate advanced capabilities for understanding and generating text, images, audio, video, and code to enhance multimedia interactions and development.</p>
        </div>
        <div className="aiplatforms-item">
          <img style={{ width: '220px', height: '70px', marginTop: '35px' }} src="/Assets/Platforms/mistral-ai-logo.png" alt="Mistral AI" />
          {/* <h2>MISTRAL AI</h2> */}
          <p>Enhance your applications with custom, portable open-source models designed for developers and businesses.</p>
        </div>
        <div className="aiplatforms-item">
          <img style={{ width: '210px', height: '80px' }} src="/Assets/Platforms/dalle-logo.svg" alt="DALL E" />
          {/* <h2>DALL-E</h2> */}
          <p>Harness AI-powered image generation to unlock new creative possibilities and bring innovative concepts to life.</p>
        </div>
        <div className="aiplatforms-item">
          <img style={{ width: '220px', height: '80px' }} src="/Assets/Platforms/ultralytics-logo-web.png" alt="Ultralytics" />
          {/* <h2>ULTRALYTICS</h2> */}
          <p>Leverage computer vision technology for real-time object detection and precise image segmentation.</p>
        </div>
        <div className="aiplatforms-item">
          <img style={{ width: '230px', height: '60px', marginTop: '42px' }} src="/Assets/Platforms/stability-ai-logo.png" alt="Stability.AI" />
          {/* <h2>STABILITY.AI</h2> */}
          <p>Speed up R&D for your GenAI projects by utilizing readily available models and datasets.</p>
        </div>
        <div className="aiplatforms-item">
          <img style={{ width: '260px', height: '60px' }} src="/Assets/Platforms/langchain-logo.png" alt="LangChain" />
          {/* <h2>LangChain</h2> */}
          <p>Streamline NLP application development with modular design and specialized tools for greater flexibility and efficiency.</p>
        </div>
        <div className="aiplatforms-item">
          <img style={{ width: '300px', height: '80px', marginTop: '10px' }} src="/Assets/Platforms/llamaindex-logo.svg" alt="Llamaindex" />
          {/* <h2>Llamaindex</h2> */}
          <p>Simplify custom data integration into your LLM with a comprehensive orchestration framework for seamless deployment and management.</p>
        </div>
        <div className="aiplatforms-item">
          <img style={{ width: '250px', height: '75px' }} src="/Assets/Platforms/pinecone-logo.svg" alt="Pinecone" />
          {/* <h2>Pinecone</h2> */}
          <p>Develop advanced AI systems by leveraging a fast, easy-to-use, and trusted serverless vector database for efficient data management and retrieval.</p>
        </div>
        <div className="aiplatforms-item">
          <img style={{ width: '260px', height: '75px' }} src="/Assets/Platforms/huggingface-logo.png" alt="Hugging Face" />
          {/* <h2>Hugging Face</h2> */}
          <p>Access cutting-edge NLP models and tools via a collaborative community platform for shared insights and innovation.</p>
        </div>
      </div>

    </div>
  );
};

export default Platforms;