import React from 'react';
import './Industries.css';
import IndustryBox from './IndustryBox';

// Import icons
import healthcare from '../assets/icons/healthcare.svg';
import educationIcon from '../assets/icons/education.svg';
import finance from '../assets/icons/finance.svg';
import blockchainIcon from '../assets/icons/icon7.png';
import technology from '../assets/icons/technology.svg';
import energy from '../assets/icons/energy.svg';
import realestate from '../assets/icons/realestate.svg';
import retail from '../assets/icons/retail.svg';
import fmcgIcon from '../assets/icons/icon4.png';
import manufacturingIcon from '../assets/icons/icon5.png';
import gamesDevelopmentIcon from '../assets/icons/icon6.png';
import socialMediaIcon from '../assets/icons/icon10.png';
import animatedVideosIcon from '../assets/icons/icon12.png';
import chatBotsIcon from '../assets/icons/icon13.png';
import eCommerceIcon from '../assets/icons/icon9.png';
import telecomm from '../assets/icons/telecomm.svg';
import transportation from '../assets/icons/transportation.svg';




const industries = [
    { title: 'Healthcare', icon: healthcare },
    { title: 'Education', icon: educationIcon },
    { title: 'Finance', icon: finance },
    { title: 'Blockchain', icon: blockchainIcon },
    { title: 'Technology', icon: technology },
    { title: 'Energy', icon: energy },
    { title: 'Real Estate', icon: realestate },
    { title: 'Retail', icon: retail },
    { title: 'FMCG', icon: fmcgIcon },
    { title: 'Manufacturing', icon: manufacturingIcon },
    { title: 'Games Development', icon: gamesDevelopmentIcon },
    { title: 'Social Media', icon: socialMediaIcon },
    { title: '3D Animated Videos', icon: animatedVideosIcon },
    { title: 'Chat Bots', icon: chatBotsIcon },
    { title: 'E-Commerce', icon: eCommerceIcon },
    { title: 'Telecomm', icon: telecomm },
    { title: 'Transportation', icon: transportation },
];

const Industries = () => {
    return (



        <div id="industries" className="industries">
            <section id="industries">
                <h1>Industries</h1>
                <div className="industry-boxes">
                    {industries.map((industry, index) => (
                        <IndustryBox key={index} title={industry.title} icon={industry.icon} />
                    ))}
                </div>
            </section>
        </div>
    );
};

export default Industries;