import React, { useState } from 'react';
import ReactTextareaAutosize from 'react-textarea-autosize';
import './GetInTouchForm.css';

const GetInTouchForm = () => {
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        companies: '',
        phone: '',
        source: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await fetch('/script.php', { // Update with the correct path
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });

        if (response.ok) {
            alert('Your message has been sent!');
            setFormData({
                fullName: '',
                email: '',
                companies: '',
                phone: '',
                source: '',
                message: '',
            });
        } else {
            alert('There was an error sending your message. Please try again later.');
        }
    };

    return (
                <form className="get-in-touch-form" onSubmit={handleSubmit}>
                    <div className="get-in-touch-form-group">
                        <input className='getintouch-outcanvas' type="text" id="fullName" name="fullName" placeholder="Full Name" value={formData.fullName} onChange={handleChange} required />
                    </div>
                    <div className="get-in-touch-form-group-row">
                    <div className="get-in-touch-form-group">
                        <input className='getintouch-outcanvas' type="email" id="email" name="email" placeholder= "Email" value={formData.email} onChange={handleChange} required />
                    </div>
                    <div className="get-in-touch-form-group">
                        <input className='getintouch-outcanvas' type="tel" id="phone" name="phone" placeholder= "Phone Number" value={formData.phone} onChange={handleChange} required />
                    </div>
                    </div>
                    <div className="get-in-touch-form-group-row">
                    <div className="get-in-touch-form-group">
                        <input className='getintouch-outcanvas' type="text" id="companies" name="companies" placeholder= "Company" value={formData.companies} onChange={handleChange} />
                    </div>
                    <div className="get-in-touch-form-group">
                        <input className='getintouch-outcanvas' type="text" id="source" name="source" placeholder= "How did you hear about us?" value={formData.source} onChange={handleChange} />
                    </div>
                    </div>
                    <div className="get-in-touch-form-group">
                        <ReactTextareaAutosize className='getintouch-outcanvas' style={{borderRadius:'30px', width: '100%', fontSize: '1.25rem', fontWeight: '400', height: 'fit-content', border: 'none', resize: 'none', padding: '0.5rem 1rem', boxShadow: 'none'}} id="message" name="message" placeholder= "Message" value={formData.message} onChange={handleChange} required/>
                    </div>
                    <button type="submit" className="get-in-touch-form-submit-btn btnTrans" style={{transition:'all 0.5s'}}>Send</button>
                </form>
    );
};

export default GetInTouchForm;
