import React, { useState } from 'react';
import './CloudSolutions.css';
import './HoverTabs.css';

export default function HoverTabs() {
    const services = [
        { title: 'Cloud Migration', textheading: 'Seamless Transition: ', text: 'Ensure a smooth and efficient migration process with minimal disruption to your business operations.', text1heading: 'Cost Efficiency: ', text1: 'Optimize resource allocation and reduce operational costs by leveraging scalable cloud infrastructure.', text2heading: 'Enhanced Security: ', text2: 'Improve data protection and compliance through advanced cloud security measures and protocols.', icon: '/Assets/CloudSolutions/migration.svg' },
        { title: 'Security', textheading: 'Data Protection: ', text: 'Safeguard sensitive information with robust encryption and access controls.', text1heading: 'Threat Detection: ', text1: 'Implement real-time monitoring and detection systems to identify and mitigate security threats.', text2heading: 'Compliance Assurance: ', text2: 'Ensure adherence to industry standards and regulatory requirements, maintaining trust and credibility.', icon: '/Assets/CloudSolutions/gcpSecurity.svg' },
        { title: 'Monitoring', textheading: 'Real-Time Insights: ', text: 'Gain continuous visibility into system performance with real-time monitoring tools.', text1heading: 'Proactive Alerts: ', text1: 'Receive instant notifications of potential issues to prevent downtime and disruptions.', text2heading: 'Performance Optimization: ', text2: 'Track key metrics to optimize resource usage and ensure peak system efficiency.', icon: '/Assets/CloudSolutions/gcpMonitoring.svg' },
        { title: 'Devops Consultation', textheading: 'Process Automation: ', text: 'Streamline workflows by automating repetitive tasks, reducing errors and speeding up delivery.', text1heading: 'CI/CD Implementation: ', text1: 'Enhance software development with Continuous Integration and Continuous Deployment pipelines for faster, more reliable releases.', text2heading: 'Collaboration Enhancement: ', text2: 'Foster better communication and collaboration between development and operations teams, driving innovation and efficiency.', icon: '/Assets/CloudSolutions/eternity.svg' },
    ];

    const [activeIndex, setActiveIndex] = useState(0);

    // useEffect({
    //     eve
    // }, services)

    return (
        <div id='Dada'>
            <div id='containers'>
                <div id='Tabs-container' style={{ width: '100%', height: '50%' }}>
                    {services.map((service, index) => (
                        <div
                            key={index}
                            id="Hover-Tab"
                            className='btnTrans mx-1'
                            style={{
                                backgroundColor: activeIndex === index ? '#61dafb' : 'rgba(0, 0, 0, 0)',
                                color: activeIndex === index ? 'black' : 'white',
                                cursor: 'pointer'
                            }}
                            onMouseEnter={() => setActiveIndex(index)}
                        >
                            <p><b>{service.title}</b></p>
                        </div>
                    ))}
                </div>



                <div className="TabsOutput-container" id='TabsOutput-container'>
                    <img src={services[activeIndex].icon} alt={services[activeIndex].title} className="cloudservices-icons TabsOut_ANI" />
                    <h1>{services[activeIndex].title}</h1>
                    <h3>
                        <ul>
                            <li><b>{services[activeIndex].textheading}</b>{services[activeIndex].text}
                            </li>
                            <br />
                            <li><b>{services[activeIndex].text1heading}</b>{services[activeIndex].text1}
                            </li>
                            <br />
                            <li><b>{services[activeIndex].text2heading}</b>{services[activeIndex].text2}
                            </li>
                        </ul>
                    </h3>
                </div>

                

            </div>
        </div>
    );
}
