import React from 'react';
import './Company.css';
import Modal from './Modal';


const Company = () => {
    return (

        <div id="company" className="company">
            <h1>Company</h1>
            <p>We’re thinkers, <br/> and we believe in creating opportunities that benefit the environment, society, and the future generations.</p>
            <p>We leverage technology, <br/> to improve lives for the better. The founders bring over three decades of experience across multiple industry verticals.</p>
            <p>At Digital1i, <br/> we thrive in Software Development and delivering custom-fit solutions for your business.</p>
            <p>Through in-depth research, <br/> we assess the effects of AI, Computer Vision, and NLP in shaping software development, business strategies, and go-to-market plans.</p>
            <br /><p>Join us to bring your ideas to life with innovation and expertise.</p>
            <br />
            <Modal />
        </div>
    );
};

export default Company;