import React from 'react';
import './AITechnologies.css';


const AITechnologies = () => {
    return (
        <div id="aitechnologies" className="aitechnologies">
            <h1>AI Tools & Technologies</h1>
            <h2>Large Enterprise Grade AI/ML Platform</h2>
            <div className="aitech-icons">
                <img src="/Assets/AITechnologies/meta-logo.svg" alt="Meta" className="aitech-icon" />
                <img src="/Assets/AITechnologies/open-ai-logo.svg" alt="Open AI" className="aitech-icon" />
                <img src="/Assets/AITechnologies/amazon-logo-web.png" alt="Amazon" className="aitech-icon" />
                <img src="/Assets/AITechnologies/google-ai-logo-2.svg" alt="Google AI" className="aitech-icon" />
                <img src="/Assets/AITechnologies/microsoft-azure-logo.svg" alt="Azure" className="aitech-icon" />
                <img src="/Assets/AITechnologies/squirro-logo.svg" alt="Squirro" className="aitech-icon" />
            </div>
            <h2>Special Purpose Tools and Models</h2>
            <div className="aitech-icons">
                <img src="/Assets/AITechnologies/mistral-ai-logo.png" alt="Mistral" className="aitech-icon" />
                <img src="/Assets/AITechnologies/open-ai-whisper.svg" alt="Open AI Whisper" className="aitech-icon" />
                <img src="/Assets/AITechnologies/dalle-logo.svg" alt="Dall E" className="aitech-icon" />
                <img src="/Assets/AITechnologies/ultralytics-logo-web.png" alt="Ultralytics" className="aitech-icon" />
                <img src="/Assets/AITechnologies/stability-ai-logo.png" alt="Stability" className="aitech-icon" />
            </div>
            <h2>Retrieval Augmented Generation (RAG)</h2>
            <div className="aitech-icons">
                <img src="/Assets/AITechnologies/langchain-logo.png" alt="Langchain" className="aitech-icon" />
                <img src="/Assets/AITechnologies/llamaindex-logo.svg" alt="Llamaindex" className="aitech-icon" />
                <img src="/Assets/AITechnologies/pinecone-logo.svg" alt="Pinecone" className="aitech-icon" />
                <img src="/Assets/AITechnologies/amazon-open-search.svg" alt="Amazon Open Search" className="aitech-icon" />
                <img src="/Assets/AITechnologies/dspy-logo.svg" alt="DSPY" className="aitech-icon" />
                <img src="/Assets/AITechnologies/huggingface-logo.png" alt="Huggingface" className="aitech-icon" />
            </div>
        </div>


    );
};

export default AITechnologies;