import React from 'react';
import './OurLocations.css';

const OurLocations = () => {
    return (
<div id="ourlocations" className="ourlocations">
    <div id="ourlocations" className="ourlocations">
      <h1>Our Locations</h1>
      </div>
      <div className="ourlocations-grid">
      <div className="ourlocations-item">
        <img src="/Assets/OurLocations/UK.jpg" alt="New York" />
        <h2>United Kingdom</h2>
        <p>89 Queenshill Avenue<br/>LS17 6BL<br/>Leeds, UK<br/>+44 7424 126 733</p>
      </div>
      <div className="ourlocations-item">
        <img src="/Assets/OurLocations/UAE.jpg" alt="London" />
        <h2>United Arab Emirates</h2>
        <p>507, Buheira Khalid,<br/>Sharjah, UAE<br/>+971 55 679 6535</p>
      </div>      
      <div className="ourlocations-item">
        <img src="/Assets/OurLocations/US.jpg" alt="Chicago" />
        <h2>United States</h2>
        <p>28th street<br/>Apt 101<br/>Alexandria VA, USA<br/>+1 (872) 314-9331</p>
      </div>
        </div>
        
      </div>      
    );
};

export default OurLocations;