import React from 'react';
import './CloudSolutions.css';
import HoverTabs from './HoverTabs';


const CloudSolutions = () => {
  return (
    <div id="cloudsolution" className="cloudsolution">
      <div id="cloudsolutions" className="cloudsolutions">
        <div id="cloudsolutions1" className="cloudsolutions1" style={{ paddingInline: '20px' }}>
          <h1>Step into the Next Era of</h1>
          <h1 style={{ marginTop: '-10px', marginBottom: '30px' }}> <span>Cloud Technology</span></h1>
          <h2>Empower your business with Digital1i Cloud Solutions</h2>
        </div>
        <div id="cloudsolutions2" className="cloudsolutions2" style={{ paddingInline: '40px' }}>
          <p>By integrating our Cloud and DevOps solutions, your business can achieve greater efficiency, flexibility, and innovation.
            <br />
            <br /> Whether you're just starting your cloud journey or looking to optimize your existing infrastructure, Our solutions build a solid base for a scalable ecosystem</p>
        </div>
      </div>
      <div id="cloudservices" className="cloudServices">
        <h1>Cloud Solutions</h1>
        <p>Our solutions leverage the power of Cloud and accelerate digital transformation</p>
      </div>

      <HoverTabs />
    </div>



  );
};

export default CloudSolutions;