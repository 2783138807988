import React from "react";
import './FlipingCard.css';
const FlipingCard = () => {

    return (
        <>
            <div style={{ height: '1rem' }}></div>
            <div className="box">
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className="flip-box">
                        <div className="flip-box-inner">
                            <div className="flip-box-front" style={{ padding: '20px' }}>
                                <div style={{ height: '50%', color: 'white', textAlign: 'left' }}>
                                    <h2>Natural Language Processing (NLP)</h2>
                                    <div style={{ backgroundColor: '#00c5d3', height: '0.2rem', width: '20%', borderRadius: '0px', marginTop: '2rem' }}></div>
                                </div>
                                <div className="space" style={{ display: 'flex', justifyContent: 'end', height: '50%' }}>
                                    <img src="/Assets/AICapabilities/naturallanguageprocessing.svg" alt="" />
                                </div>
                            </div>
                            <div className="flip-box-back" style={{ padding: '15px'}}>
                                <h5 style={{ color: '#00c5d3', textAlign: 'left', fontSize:'clamp(.8rem, 1.5vw, 2rem)'}}>Natural Language Processing (NLP)</h5>
                                <br />
                                <ul>
                                    <li>
                                        <b>Text Analysis:</b> Automatically extract insights, sentiments, and key information from vast amounts of text data.
                                    </li>
                                    <br />
                                    <li>
                                        <b>Language Translation:</b> Facilitate seamless communication across languages with accurate, real-time translation.
                                    </li>
                                    <br />
                                    <li>
                                        <b>Sentiment Analysis:</b> Gauge customer emotions and opinions through social media, reviews, and feedback.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className="flip-box">
                        <div className="flip-box-inner">
                            <div className="flip-box-front" style={{ padding: '20px' }}>
                                <div style={{ height: '50%', color: 'white', textAlign: 'left' }}>
                                    <h2>Computer <br /> Vision</h2>
                                    <div style={{ backgroundColor: '#00c5d3', height: '0.2rem', width: '20%', borderRadius: '0px', marginTop: '2rem' }}></div>
                                </div>
                                <div className="space" style={{ display: 'flex', justifyContent: 'end', height: '50%' }}>
                                    <img src="/Assets/AICapabilities/computervision.svg" alt="" />
                                </div>
                            </div>
                            <div className="flip-box-back" style={{ padding: '15px'}}>
                                <h5 style={{ color: '#00c5d3', textAlign: 'left', fontSize:'clamp(.8rem, 1.5vw, 2rem)'}}>Computer Vision</h5>
                                <br />
                                <ul>
                                <li>
                                    <b>Object Detection:</b>  Identify and locate objects within images or videos for applications like security and automation.
                                </li>
                                <br />
                                <li>

                                    <b>Facial Recognition:</b> Accurately recognize and authenticate individuals, enhancing security and personalization.
                                </li>
                                <br />
                                <li>
                                    <b>Image Classification:</b> Categorize images into predefined classes, enabling efficient organization and retrieval.
                                </li>
</ul>
                            </div>
                        </div>
                    </div>
                </div>



                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className="flip-box">
                        <div className="flip-box-inner">
                            <div className="flip-box-front" style={{ padding: '20px' }}>
                                <div style={{ height: '50%', color: 'white', textAlign: 'left' }}>
                                    <h2>Generative <br /> AI / LLM</h2>
                                    <div style={{ backgroundColor: '#00c5d3', height: '0.2rem', width: '20%', borderRadius: '0px', marginTop: '2rem' }}></div>
                                </div>
                                <div className="space" style={{ display: 'flex', justifyContent: 'end', height: '50%' }}>
                                    <img src="/Assets/AICapabilities/generativeai.svg" alt="" />
                                </div>
                            </div>
                            <div className="flip-box-back" style={{ padding: '15px'}}>
                                <h5 style={{ color: '#00c5d3', textAlign: 'left', fontSize:'clamp(.8rem, 1.5vw, 2rem)'}}>Generative AI / LLM</h5>
                                <br />
                                <ul>
                                <li>
                                    <b>Content Creation:</b> Generate high-quality text, images, and media for marketing, entertainment, and education.
                                </li>
                                <br />
                                <li>

                                    <b>Creative Assistance:</b> Enhance creative processes by providing inspiration and drafting ideas for writers, designers, and artists.
                                </li>
                                <br />
                                <li>
                                    <b>Conversational AI:</b> Power advanced chatbots and virtual assistants that engage in human-like, context-aware conversations.
                                </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ height: '3rem' }}></div>


            <div className="box">
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className="flip-box">
                        <div className="flip-box-inner">
                            <div className="flip-box-front" style={{ padding: '20px' }}>
                                <div style={{ height: '50%', color: 'white', textAlign: 'left' }}>
                                    <h2>AI/ ML Consultation <br /> Services</h2>
                                    <div style={{ backgroundColor: '#00c5d3', height: '0.2rem', width: '20%', borderRadius: '0px', marginTop: '2rem' }}></div>
                                </div>
                                <div className="space" style={{ display: 'flex', justifyContent: 'end', height: '50%' }}>
                                    <img src="/Assets/AICapabilities/aimlconsulatation.svg" alt="" />
                                </div>
                            </div>
                            <div className="flip-box-back" style={{ padding: '15px'}}>
                                <h5 style={{ color: '#00c5d3', textAlign: 'left', fontSize:'clamp(.8rem, 1.5vw, 2rem)'}}>AI/ ML Consultation Services</h5>
                                <br />
                                <ul>
                                <li>
                                    <b>Solution Design:</b> Design and implement AI/ML solutions tailored to address specific operational needs and drive efficiency.
                                </li>
                                <br />
                                <li>

                                    <b>Data Strategy:</b> Optimize data management and preparation to ensure quality inputs for AI/ML models.
                                </li>
                                <br />
                                <li>
                                    <b>Model Selection:</b> Guide the selection of appropriate algorithms and models to achieve the desired outcomes.
                                </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className="flip-box">
                        <div className="flip-box-inner">
                            <div className="flip-box-front" style={{ padding: '20px' }}>
                                <div style={{ height: '50%', color: 'white', textAlign: 'left' }}>
                                    <h2>Data <br /> Science</h2>
                                    <div style={{ backgroundColor: '#00c5d3', height: '0.2rem', width: '20%', borderRadius: '0px', marginTop: '2rem' }}></div>
                                </div>
                                <div className="space" style={{ display: 'flex', justifyContent: 'end', height: '50%' }}>
                                    <img src="/Assets/AICapabilities/datascience.svg" alt="" />
                                </div>
                            </div>
                            <div className="flip-box-back" style={{ padding: '15px'}}>
                                <h5 style={{ color: '#00c5d3', textAlign: 'left', fontSize:'clamp(.8rem, 1.5vw, 2rem)'}}>Data Science</h5>
                                <br />
                                <ul>
                                <li>
                                    <b>Data Analysis:</b> Apply statistical methods and algorithms to interpret complex data sets and uncover trends.
                                </li>
                                <br />
                                <li>

                                    <b>Predictive Modeling:</b> Build models to forecast future outcomes and make data-driven decisions.
                                </li>
                                <br />
                                <li>
                                    <b>Machine Learning:</b> Implement algorithms that enable systems to learn from data and improve over time.
                                </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>



                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className="flip-box">
                        <div className="flip-box-inner">
                            <div className="flip-box-front" style={{ padding: '20px' }}>
                                <div style={{ height: '50%', color: 'white', textAlign: 'left' }}>
                                    <h2>Custom <br /> Models</h2>
                                    <div style={{ backgroundColor: '#00c5d3', height: '0.2rem', width: '20%', borderRadius: '0px', marginTop: '2rem' }}></div>
                                </div>
                                <div className="space" style={{ display: 'flex', justifyContent: 'end', height: '50%' }}>
                                    <img src="/Assets/AICapabilities/custommodels.svg" alt="" />
                                </div>
                            </div>
                            <div className="flip-box-back" style={{ padding: '15px'}}>
                                <h5 style={{ color: '#00c5d3', textAlign: 'left', fontSize:'clamp(.8rem, 1.5vw, 2rem)'}}>Custom Models</h5>
                                <br />
                                <ul>
                                <li>
                                    <b>Tailored Solutions:</b> Develop models specifically designed to meet the unique requirements and objectives of your business.
                                </li>
                                <br />
                                <li>

                                    <b>Performance Optimization: </b> Fine-tune models for enhanced accuracy, efficiency, and relevance to your specific use case.
                                </li>
                                <br />
                                <li>
                                    <b>Integration Flexibility:</b> Seamlessly integrate custom models with existing systems and workflows for cohesive functionality.
                                </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FlipingCard;