import React, { Component } from 'react';
import { ProgressBar } from 'react-bootstrap';

export default class Loading extends Component {
    constructor() {
        super();
        this.state = {
            progress: 0,
            hidden: false,
        }
    }
    componentDidMount() {
        setTimeout(() => {
            this.setState({
                progress: 100
            })
            setTimeout(() => {
                this.setState({
                    hidden: true
                })
            }, 1000);
        }, 250);
    }
    render() {
        return (
            <div>
                <ProgressBar animated hidden={this.state.hidden} style={{ height: '0.30rem', width: '98.6%', position: 'fixed', zIndex: '4' }} variant="danger" now={this.state.progress} />
            </div>
        )
    }
}
