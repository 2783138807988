import React, { useState } from 'react'
import './Zalzlah.css'

export default function Zalzlah() {
    const [animeActive, setAnimeActive] = useState()
    return (
        <div >

            {/* <div >
                <div id='border-card-container' style={{ zIndex: '0', position: 'relative' }}>
                    <div className="card">
                    </div>
                </div>
            </div> */}

            <div style={{ width: '100%', height: '30rem', display: 'flex', justifyContent: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: "rgba(0,0,0, 0.0)" }}>

                <div onMouseEnter={() => { setAnimeActive(0) }} onMouseLeave={() => { setAnimeActive() }}>
                    <a id='border-card-container' href="/modal" style={{ textDecoration: 'none', zIndex: '0', position: 'relative', height: 'fit-content', backgroundColor: 'transparent' }}>
                        <div id='isbtnkidiv' className={animeActive === 0 ? 'card' : ''} style={{ backgroundColor: animeActive === 0 ? '' : '', transition: 'all 1s', color: animeActive === 0 ? '' : '', position: 'relative', left: '0', height: '10rem', width: '20rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '10px', cursor: 'pointer' }}>
                            <h3>Modal</h3>
                        </div>
                    </a>
                </div>

                <div style={{ width: '2rem' }}></div>

                <div onMouseEnter={() => { setAnimeActive(1) }} onMouseLeave={() => { setAnimeActive() }}>
                    <a href="/flip" style={{ textDecoration: 'none' }}>
                        <div id='isbtnkidiv' className={animeActive === 1 ? 'card' : ''} style={{ backgroundColor: animeActive === 1 ? '#052b2f' : 'white', transition: 'all 1s', color: animeActive === 1 ? 'white' : 'black', position: 'relative', left: '0', height: '10rem', width: '20rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '10px', cursor: 'pointer' }}>
                            <h3>Flip Card</h3>
                        </div>
                    </a>
                </div>

                <div style={{ width: '2rem' }}></div>

                <div onMouseEnter={() => { setAnimeActive(2) }} onMouseLeave={() => { setAnimeActive() }}>
                    <a href="/hovertabs" style={{ textDecoration: 'none' }}>
                        <div id='isbtnkidiv' className={animeActive === 2 ? 'card' : ''} style={{ backgroundColor: animeActive === 2 ? '#052b2f' : 'white', transition: 'all 1s', color: animeActive === 2 ? 'white' : 'black', position: 'relative', left: '0', height: '10rem', width: '20rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '10px', cursor: 'pointer' }}>
                            <h3>Hover Tabs</h3>
                        </div>
                    </a>
                </div>

                <div style={{ width: '2rem' }}></div>

            </div>
        </div>
    )
}
