import React from 'react';
import { Accordion } from 'react-bootstrap';
import './Jethalal.css';

export default function Jethalal() {

    const naamPatalagao = (event) => {
        console.log(event.target.className)
    }

    return (
        <>
            <div className="space"></div>
            <Accordion onClick={naamPatalagao}>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Skill Development And Growth</Accordion.Header>
                    <Accordion.Body><ul><li>
                        We foster an environment where software professionals are continuously exposed to the latest advancements in AI, enabling them to expand their skill sets in areas such as machine learning, natural language processing, and computer vision.</li><br/><li>This not only enhances their technical expertise but also positions them as valuable assets in the evolving tech landscape.
                        </li></ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Innovative Project Work</Accordion.Header>
                    <Accordion.Body><ul><li>
                        Professionals have the opportunity to work on groundbreaking projects that incorporate AI technologies, allowing them to apply theoretical knowledge to real-world problems.</li><br/><li>This hands-on experience drives professional growth and prepares them for future challenges in the industry.
                        </li></ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Collaborative Ecosystem</Accordion.Header>
                    <Accordion.Body><ul><li>
                        Our company encourages collaboration between AI specialists, software developers, and data scientists, fostering a multidisciplinary approach to problem-solving.</li><br/><li>This collaborative culture leads to the development of innovative solutions that push the boundaries of what's possible in software engineering.
                        </li></ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>Customized AI Solutions</Accordion.Header>
                    <Accordion.Body><ul><li>
                        We develop tailored AI solutions that address the unique needs of businesses, helping them optimize operations, reduce costs, and enhance customer experiences.</li><br/><li>By leveraging AI, business owners can make data-driven decisions that drive growth and improve overall efficiency.
                        </li></ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>Market Differentiation</Accordion.Header>
                    <Accordion.Body><ul><li>
                        Our AI-driven innovations allow businesses to differentiate themselves in a competitive market.</li><br/><li>By integrating advanced technologies like NLP and computer vision into their products and services, businesses can offer unique value propositions that attract and retain customers
                        </li></ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header>Scalable and Future-Proof Strategies</Accordion.Header>
                    <Accordion.Body><ul><li>
                        We work closely with business owners to design scalable AI solutions that grow with their business.</li><br/><li>Our focus on future-proof strategies ensures that as the AI landscape evolves, businesses remain at the forefront of innovation, ready to adapt and thrive in a dynamic market.
                        </li></ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header>Enhanced Decision-Making</Accordion.Header>
                    <Accordion.Body><ul><li>
                        By providing insights derived from AI-driven data analysis, we empower business owners with the information they need to make strategic decisions.</li><br/><li>This enhances their ability to respond to market trends, optimize their offerings, and ultimately, achieve better business outcomes.
                        </li></ul>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

        </>
    );
}
