import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import ContactUs from './Pages/ContactUs';
import JobApplicationForm from './Pages/JobApplicationForm';
import Layout from './Pages/Layout';
import Footer from './components/Footer';
import Header from './components/Header';
import Zalzlah from './components/Zalzlah';


function App() {

  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Layout />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/job/:jobId" element={<JobApplicationForm />} />
          <Route path="/zalzalah" element={<Zalzlah />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;