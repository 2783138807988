import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import './JobApplicationForm.css';
import Loading from '../components/Loading';

function JobApplicationForm() {
  const { jobId } = useParams();

  const jobDescriptions = {
    'Backend-Developer': {
      title: 'BackEnd Developer (.NET Core)',
      description: 'Join our team at Digital1i as a Backend Developer specializing in .NET Core. You will play a pivotal role in designing, developing, and maintaining scalable backend solutions that power our innovative software products.',
    },
    'React-Developer': {
      title: 'React Developer',
      description: 'Embark on an exciting journey with Digital1i as a React Developer. Join our team to craft innovative front-end solutions using React.js, collaborating closely with designers and backend developers to deliver seamless user experiences.',
    },
    'Cross-Platform-Developer': {
      title: 'Cross Platform Developer',
      description: 'Join Digital1i as a Cross-Platform Developer and be part of a team driving innovation across multiple platforms. Utilize your expertise to develop versatile applications that deliver seamless experiences across different devices and operating systems.',
    },
    'QA-Engineer': {
      title: 'QA Engineer',
      description: 'Become a pivotal part of Digital1i as a QA Engineer. Join our team to ensure the highest quality standards for our software products through rigorous testing and quality assurance processes.',
    },
  };

  const jobDetails = jobDescriptions[jobId];

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    resume: null,
    coverLetter: null,
    linkedInProfile: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append('firstName', formData.firstName);
    formDataToSend.append('lastName', formData.lastName);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('phone', formData.phone);
    formDataToSend.append('resume', formData.resume);
    formDataToSend.append('coverLetter', formData.coverLetter);
    formDataToSend.append('linkedInProfile', formData.linkedInProfile);
    formDataToSend.append('jobTitle', jobDetails.title); // Adding job title to form data

    const response = await fetch('/jobscript.php', {
      method: 'POST',
      body: formDataToSend,
    });

    if (response.ok) {
      alert('Your application has been sent!');
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        resume: null,
        coverLetter: null,
        linkedInProfile: '',
      });
    } else {
      alert('There was an error sending your application. Please try again later.');
    }
  };

  if (!jobDetails) {
    return <p>Job not found.</p>;
  }

  return (
    <div id="jobapplication" className="jobapplication">
      <Loading/>
      <div className="job-application">
        <h1>{jobDetails.title}</h1>
        <p>{jobDetails.description}</p>
        <form onSubmit={handleSubmit} className="job-application-form">
          <h2>Apply for this Job</h2>
          <div>
            <label>First Name *</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Last Name *</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Email *</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Phone *</label>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Resume/CV *</label>
            <input
              type="file"
              name="resume"
              accept=".pdf,.doc,.docx,.txt,.rtf"
              onChange={handleFileChange}
              required
            />
          </div>
          <div>
            <label>Cover Letter</label>
            <input
              type="file"
              name="coverLetter"
              accept=".pdf,.doc,.docx,.txt,.rtf"
              onChange={handleFileChange}
            />
          </div>
          <div>
            <label>LinkedIn Profile</label>
            <input
              type="url"
              name="linkedInProfile"
              value={formData.linkedInProfile}
              onChange={handleChange}
            />
          </div>
          <button type="submit">Submit Application</button>
        </form>
        <div style={{ height: '2rem' }}></div>
      </div>
    </div>
  );
}

export default JobApplicationForm;
