import React, { useState } from 'react';
import './Footer.css';


const Footer = () => {


    
  const [formOpen, setFormOpen] = useState(false); // State to handle form visibility

    const handleLinkClick = (id) => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };

      const handleFormToggle = () => {
        setFormOpen(!formOpen);
      };

return (

<footer className="footer">
<div className="footer-content">
  <div className="footer-logo">
  <img
      src="/Assets/Logo/logo.png"
      alt="Digital1i Logo"
      onClick={() => handleLinkClick('home')}
    />
    <p>Digital1i is a digital development company, helping businesses with product design, development, and technology acceleration.</p>
  </div>
  <div className="footer-links">
    <div className="footer-column1">
      <ul>
        <li><a href="/#ai">AI</a></li>
        <li><a href="/#cloudsolutions">Cloud Solutions</a></li>
        <li><a href="/#services">Services</a></li>
        <li><a href="/#company">Company</a></li>
      </ul>
    </div>
    <div className="footer-column2">
      <ul>
        <li><a href="/#platforms">Platforms</a></li>
        <li><a href="/#industries">Industries</a></li>
        <li><a href="/#technologies">Technologies</a></li>
        <li><a href="/#careers">Careers</a></li>
      </ul>
    </div>
    <div className="footer-column3">
      <ul>
        <li>
            <a href="/ContactUs">ContactUs</a>
          </li>
      </ul>
    </div>
  </div>
</div>

  <div className="footer-contact">
    <p>+1 (872) 314-9331</p>
    <p>info@digital1i.com</p>
    <p>28th Street, Apt 101, Alexandria VA, USA</p>
      </div>
</footer>


);
};

export default Footer;