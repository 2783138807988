import React, { useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import './Modal.css';
import Jethalal from './Jethalal';

export default function Modal() {
    const [openModal, setOpenModal] = useState(false)

    function openNav(event) {
        document.getElementById("myNav").style.height = "96%";
        setOpenModal(true)
        console.log(event.target)
    }

    function closeNav() {
        document.getElementById("myNav").style.height = "0%";
        setOpenModal(false)
    }

    return (
        <div>
            <div style={{ backgroundColor: 'rgb(0, 0, 0, 0.5)', margin: '0px -20px', height: '100%', width: '100%', position: 'absolute', zIndex: '60', top: '0rem' }} hidden={!openModal} onClick={closeNav}></div>
            <div id="myNav" className="overlay" style={{ zIndex: '60', height: openModal ? '100%' : '0%', overflow: 'hiden' }}>

                <div className="overlay-display closebtn" style={{cursor: 'pointer', height: '20%', width: '3%', textDecoration: 'none', fontFamily: 'cursive', fontSize: '2rem' }} onClick={closeNav}> <i class="fa-solid fa-circle-xmark"></i> </div>

                <div className="overlay-content">
                    <h2 className="overlay-display">What we do?</h2>
                    <div className='Wo'>
                    <br />
                    <div style={{ marginInline: 'auto', marginBottom: '10px', width: 'auto', color: 'white' }}>
                        <Tab.Container defaultActiveKey='link-0'>
                            <Nav justify variant="tabs" defaultActiveKey="home" style={{ border: 'none', position: 'relative', width: '95%', marginLeft: '30px' }}>
                                <Nav.Item>
                                    <Nav.Link className='nav-tab' eventKey="link-0">Skill Development and Growth</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className='nav-tab' eventKey="link-1">Innovative Project Work</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className='nav-tab' eventKey="link-2">Collaborative Ecosystem</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className='nav-tab' eventKey="link-3">Customized AI Solutions</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className='nav-tab' eventKey="link-4">Marketing Differentiation</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className='nav-tab' eventKey="link-5">Scalable and Future-Proof Strategies</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className='nav-tab' eventKey="link-6">Enhanced Decision-Making</Nav.Link>
                                </Nav.Item>
                            </Nav>

                            <div style={{ width: '96%', height: '100%', backgroundColor: 'transparent', alignSelf: 'center', marginLeft: '0px', marginInline: 'auto', border: '1px solid white', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px' }}>
                                <Tab.Content className='tab-content'>
                                    <Tab.Pane eventKey="link-0"><ul><li>We foster an environment where software professionals are continuously exposed to the latest advancements in AI, enabling them to expand their skill sets in areas such as machine learning, natural language processing, and computer vision.</li><br/><li>This not only enhances their technical expertise but also positions them as valuable assets in the evolving tech landscape.</li></ul></Tab.Pane>
                                    <Tab.Pane eventKey="link-1"><ul><li>Professionals have the opportunity to work on groundbreaking projects that incorporate AI technologies, allowing them to apply theoretical knowledge to real-world problems.</li><br/><li>This hands-on experience drives professional growth and prepares them for future challenges in the industry.</li></ul></Tab.Pane>
                                    <Tab.Pane eventKey="link-2"><ul><li>Our company encourages collaboration between AI specialists, software developers, and data scientists, fostering a multidisciplinary approach to problem-solving.</li><br/><li>This collaborative culture leads to the development of innovative solutions that push the boundaries of what's possible in software engineering.</li></ul></Tab.Pane>
                                    <Tab.Pane eventKey="link-3"><ul><li>We develop tailored AI solutions that address the unique needs of businesses, helping them optimize operations, reduce costs, and enhance customer experiences.</li><br/><li>By leveraging AI, business owners can make data-driven decisions that drive growth and improve overall efficiency.</li></ul></Tab.Pane>
                                    <Tab.Pane eventKey="link-4"><ul><li>Our AI-driven innovations allow businesses to differentiate themselves in a competitive market.</li><br/><li> By integrating advanced technologies like NLP and computer vision into their products and services, businesses can offer unique value propositions that attract and retain customers.</li></ul></Tab.Pane>
                                    <Tab.Pane eventKey="link-5"><ul><li>We work closely with business owners to design scalable AI solutions that grow with their business.</li><br/><li>Our focus on future-proof strategies ensures that as the AI landscape evolves, businesses remain at the forefront of innovation, ready to adapt and thrive in a dynamic market.</li></ul></Tab.Pane>
                                    <Tab.Pane eventKey="link-6"><ul><li>By providing insights derived from AI-driven data analysis, we empower business owners with the information they need to make strategic decisions.</li><br/><li>This enhances their ability to respond to market trends, optimize their offerings, and ultimately, achieve better business outcomes.</li></ul></Tab.Pane>
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                    </div>
                </div>
                <Jethalal/>
            </div>

            <span style={{ fontSize: '18px', cursor: 'pointer' }} className="more" onClick={openNav}> Read More </span>


        </div>
    )
}