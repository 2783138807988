import React from 'react';
import './AI.css';

const AI = () => {
  return (
    <div id="ai" className="ai">
      <div id="ai1" className="ai1" style={{ paddingInline: '10px' }}>
        <h1>Kickstart Your AI Journey With <span>Digital1i</span></h1>
        <h2>Your Trusted Partner in everything AI</h2>
      </div>
      <div id="ai2" className="ai2" style={{ paddingInline: '20px' }}>
        <p>We empower businesses to innovate and thrive in an ever-evolving landscape, driven by AI.
          <br />
          <br /> We excel in Natural Language Processing , Computer Vision, Generative AI and many other AI areas where we’ve deployed numerous pre-built models across diverse industries.
          <br />
          <br /> Our AI team can help you leverage your valuable business data and deliver cutting-edge solutions smartly.</p>
      </div>
    </div>
  );
};
export default AI;