import React from 'react';
import './Technologies.css';

const Technologies = () => {
    return (

        <div id="technologies" className="technologies">
            <h1 className="heading1">Technologies</h1>
            <p className="subheading1">Our technology stack leverages scalable solutions for your business challenges</p>
            <div className="tech-icons">
                <div className="mx-5 tech-icon-box">
                    <img src="/Assets/Technologies/aws.png" alt="AWS" className="tech-icon" />
                </div>
                <div className="mx-5 tech-icon-box">
                    <img src="/Assets/Technologies/azure.png" alt="Azure" className="tech-icon" />
                </div>
                <div className="mx-5 tech-icon-box">
                    <img src="/Assets/Technologies/GCP.png" alt="Google Cloud Platform" className="tech-icon" />
                </div>
                <div className="mx-5 tech-icon-box">
                    <img src="/Assets/Technologies/node.png" alt="Node" className="tech-icon" />
                </div>
            </div>
            <div className="tech-icons">
                <div className="mx-5 tech-icon-box">
                    <img src="/Assets/Technologies/dot net core.png" alt=".net core" className="tech-icon" />
                </div>
                <div className="mx-5 tech-icon-box">
                    <img src="/Assets/Technologies/ios.png" alt="ios" className="tech-icon" />
                </div>
                <div className="mx-5 tech-icon-box">
                    <img src="/Assets/Technologies/android.png" alt="Android" className="tech-icon" />
                </div>
                <div className="mx-5 tech-icon-box">
                    <img src="/Assets/Technologies/react native.png" alt="React / React Native" className="tech-icon" />
                </div>

            </div>
        </div>

    );
};

export default Technologies;