import React, { useState } from 'react';
import './Careers.css';

const Careers = () => {

  const [animeActive, setAnimeActive] = useState()

  return (
    <div id="careers" className="careers">
      <h1 className="heading">Careers</h1><br />
      <p className="subheading">Join our team and grow your career with us<br /><br />Please email <span style={{ color: 'var(--maincolor2)' }}>hr@digital1i.com</span></p>
      <div style={{ height: '1rem' }}></div>

      <section className="career">
        <div className="job-listing">
          <a href="/job/Backend-Developer" style={{ textDecoration: 'none', zIndex: '0', position: 'relative', height: 'fit-content', width: '280px', backgroundColor: 'transparent' }} >
            <div id='isbtnkidiv' className={animeActive === 0 ? 'card' : ''} style={{ backgroundColor: animeActive === 0 ? 'var(--clr-1)' : '#f0f0f0', transition: 'all 1s', color: animeActive === 0 ? 'white' : 'black', position: 'relative', left: '0', borderRadius: '10px', cursor: 'pointer', padding: '20px' }} onMouseEnter={() => { setAnimeActive(0) }} onMouseLeave={() => { setAnimeActive() }}>
              <h4>BackEnd Developer (.net core)</h4>

              <div style={{ backgroundColor: '', display: 'flex', justifyContent: 'end' }}>
              <div id='applyNowbtn-Container' style={{ display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>

              <a className="job-apply-now" href="/job/Backend-Developer" style={{ color: animeActive === 0 ? 'var(--maincolor1)' : '#0045a6', transition: 'all 1s' }}>Apply Now</a>
              <div id='poocha' style={{ height: '.1rem', backgroundColor: 'white', marginLeft: '30%', bottom: '0.8rem', position:'relative' }}></div>
              </div>
              </div>
              
            </div>
          </a>
          <a href="/job/React-Developer" style={{ textDecoration: 'none', zIndex: '0', position: 'relative', height: 'fit-content', width: '280px', backgroundColor: 'transparent' }} >
            <div id='isbtnkidiv' className={animeActive === 1 ? 'card' : ''} style={{ backgroundColor: animeActive === 1 ? 'var(--clr-1)' : '#f0f0f0', transition: 'all 1s', color: animeActive === 1 ? 'white' : 'black', position: 'relative', left: '0', borderRadius: '10px', cursor: 'pointer', padding: '20px' }} onMouseEnter={() => { setAnimeActive(1) }} onMouseLeave={() => { setAnimeActive() }}>
              <h4>React Developer</h4>
              <br />
              <div style={{ backgroundColor: '', display: 'flex', justifyContent: 'end' }}>
                <div id='applyNowbtn-Container' style={{ display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
                  <a className="job-apply-now" href="/job/React-Developer" style={{ color: animeActive === 1 ? 'var(--maincolor1)' : '#0045a6', transition: 'all 1s', position: 'relative' }}>Apply Now</a>
                  <div id='poocha' style={{ height: '.1rem', backgroundColor: 'white', marginLeft: '30%', bottom: '0.8rem', position:'relative' }}></div>
                </div>
              </div>
            </div>
          </a>
          <a href="/job/Cross-Platform-Developer" style={{ textDecoration: 'none', zIndex: '0', position: 'relative', height: 'fit-content', width: '280px', backgroundColor: 'transparent' }} >
            <div id='isbtnkidiv' className={animeActive === 2 ? 'card' : ''} style={{ backgroundColor: animeActive === 2 ? 'var(--clr-1)' : '#f0f0f0', transition: 'all 1s', color: animeActive === 2 ? 'white' : 'black', position: 'relative', left: '0', borderRadius: '10px', padding: '20px' }} onMouseEnter={() => { setAnimeActive(2) }} onMouseLeave={() => { setAnimeActive() }}>
              <h4>Cross Platform Developer</h4>
              <div style={{ backgroundColor: '', display: 'flex', justifyContent: 'end' }}>
                <div id='applyNowbtn-Container' style={{ display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
                  <a className="job-apply-now" href="/job/Cross-Platform-Developer" style={{ color: animeActive === 2 ? 'var(--maincolor1)' : '#0045a6', transition: 'all 1s' }}>Apply Now</a>
                  <div id='poocha' style={{ height: '.1rem', backgroundColor: 'white', marginLeft: '30%', bottom: '0.8rem', position:'relative' }}></div>
                  </div>
              </div>
            </div>
          </a>
          <a href="/job/QA-Engineer" style={{ textDecoration: 'none', zIndex: '0', position: 'relative', height: 'fit-content', width: '280px', backgroundColor: 'transparent' }} >
            <div id='isbtnkidiv' className={animeActive === 3 ? 'card' : ''} style={{ backgroundColor: animeActive === 3 ? 'var(--clr-1)' : '#f0f0f0', transition: 'all 1s', color: animeActive === 3 ? 'white' : 'black', position: 'relative', left: '0', borderRadius: '10px', cursor: 'pointer', padding: '20px' }} onMouseEnter={() => { setAnimeActive(3) }} onMouseLeave={() => { setAnimeActive() }}>
              <h4>QA Engineer</h4>
              <br />
              <div style={{ backgroundColor: '', display: 'flex', justifyContent: 'end' }}>
                <div id='applyNowbtn-Container' style={{ display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
                  <a className="job-apply-now" href="/job/QA-Engineer" style={{ color: animeActive === 3 ? 'var(--maincolor1)' : '#0045a6', transition: 'all 1s', position: 'relative' }}>Apply Now</a>
                  <div id='poocha' style={{ height: '.1rem', backgroundColor: 'white', marginLeft: '30%', bottom: '0.8rem', position:'relative' }}></div>
                </div>
              </div>
            </div>
          </a>

        </div>
        <div style={{ height: '2rem' }}></div>
      </section>

    </div>
  );
};

export default Careers;


