import React from 'react';
import './AICapabilities.css';
import FlipingCard from './FlipingCard';

const AI = () => {
  return (
    <div id="aicapabilities" className="aicapabilities">
      <div id="ouraicapabilities" className="ouraicapabilities">
        <div style={{ height: '1rem' }}></div>
        <h1>Our AI Capabilities</h1>
        <p>Drive innovation and enhance decision-making with advanced models</p>
      </div>
      <FlipingCard />

      <div style={{ height: '5rem' }}></div>

    </div>
  );
};

export default AI;