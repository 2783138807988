import React, { useState } from 'react';
import Loading from '../components/Loading';
import OurLocations from '../components/OurLocations';
import './ContactUs.css';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    companies: '',
    phone: '',
    source: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch('/script.php', { // Update with the correct path
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      alert('Your message has been sent!');
      setFormData({
        fullName: '',
        email: '',
        companies: '',
        phone: '',
        source: '',
        message: '',
      });
    } else {
      alert('There was an error sending your message. Please try again later.');
    }
  };

  return (<>
    <Loading/>
    <div id="contact-us" className="section">
      <img src="/Assets/Contactus/Contactus.jpg" alt="ContactUs" className="section-image" />
      <div className="contactus">
        <h1>We’re<br/> The Complete Solution.<br/>Connect With Us,<br/>And Let’s See How We Can Bring Your Vision To Life.</h1>
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <input type="text" id="fullName" name="fullName" placeholder="Full Name" value={formData.fullName} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <input type="email" id="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <input type="tel" id="phone" name="phone" placeholder="Phone Number" value={formData.phone} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <input type="text" id="companies" name="companies" placeholder="Company" value={formData.companies} onChange={handleChange} />
          </div>
          <div className="form-group">
            <input type="text" id="source" name="source" placeholder="How did you hear about us?" value={formData.source} onChange={handleChange} />
          </div>
          <div className="form-group">
            <textarea id="message" name="message" placeholder="Message" value={formData.message} onChange={handleChange} required></textarea>
          </div>
          <button type="submit" style={{transition:'all 0.8s'}} className="submits-btn">Send</button>
        </form>
      </div>
      <OurLocations />
    </div>
    </>

  );
};

export default ContactUs;
